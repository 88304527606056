import React from 'react'
import ReactDOM from 'react-dom/client'

import { StorageReference } from 'firebase/storage'
import { getVersions, getEnvironments, getPlatforms, getApp } from './firebase'

import { TypographyStylesProvider, Center, Card, Loader, Stack, Group } from '@mantine/core'

function Platform(props: { environment: string, platform: { app?: StorageReference; platform: string } }) {
  const [loading, setLoading] = React.useState(true)
  const [app, setApp] = React.useState<{ name: string, url: string } | null>(null)
  React.useEffect(() => {
    if (props.platform.app) {
      getApp(props.platform.app).then(setApp).finally(() => setLoading(false))
    }
  }, [])

  const urlPrefix = props.platform.platform === 'ios'
    ? 'itms-services://?action=download-manifest&amp;url='
    : ''

  return (
    <a href={urlPrefix + app?.url}>
      <Card
        withBorder
        sx={{
          width: '8rem',
          height: '8rem',
          cursor: 'pointer',
          backgroundColor: '#FAFAFA',
          ':hover': { backgroundColor: '#F5F5F5' },
          ':active': { backgroundColor: '#F0F0F0' }
        }}
      >
        {
          loading ? (
            <Center style={{ height: '100%' }}>
              <Loader />
            </Center>
          ) : (
            <Stack>
              <Center style={{ fontWeight: 700, textTransform: 'capitalize' }}>
                {props.environment}
              </Center>

              <img
                alt="Android" src={props.platform.platform === 'ios' ? '/apple.svg' : '/android.svg'}
                style={{ width: '3rem', height: '2.5rem', margin: 'auto' }}
              />
            </Stack>
          )
        }
      </Card>
    </a>
  )
}

function Environment(props: { environment: StorageReference }) {
  const [loading, setLoading] = React.useState(true)
  const [platforms, setPlatforms] = React.useState<{ app?: StorageReference, platform: string }[]>([])
  React.useEffect(() => {
    getPlatforms(props.environment).then(setPlatforms).finally(() => setLoading(false))
  }, [])

  return (
    <>
      {
        !loading && (
          <Group>
            {platforms.map((platform) => <Platform
              key={platform.platform}
              platform={platform}
              environment={props.environment.name}
            />)}
          </Group>
        )
      }
    </>
  )
}

function Version(props: { version: StorageReference }) {
  const [loading, setLoading] = React.useState(true)
  const [environments, setEnvironments] = React.useState<StorageReference[]>([])
  React.useEffect(() => {
    getEnvironments(props.version).then(setEnvironments).finally(() => setLoading(false))
  }, [])

  return (
    <Stack>
      <Center>
        <h2 style={{ marginTop: '0.5rem' }}>{props.version.name}</h2>
      </Center>

      {
        loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Group position="center">
            {environments.map((environment) => <Environment key={environment.name} environment={environment} />)}
          </Group>
        )
      }
    </Stack>
  )
}

const Versions = () => {
  const [loading, setLoading] = React.useState(true)
  const [version, setVersions] = React.useState<StorageReference[]>([])
  React.useEffect(() => {
    getVersions().then(setVersions).finally(() => setLoading(false))
  }, [])

  if (loading) {
    return (
      <Center my="2rem">
        <Loader />
      </Center>
    )
  }

  return (
    <Stack>
      {version.map(version => <Version key={version.name} version={version} />)}
    </Stack>
  )
}

ReactDOM.createRoot(document.getElementById('react-root')!).render(
  <React.StrictMode>
    <TypographyStylesProvider>
      <Center>
        <h1 style={{ marginBottom: '1rem' }}>Nap&Up - Applications de Développement</h1>
      </Center>
      <Center>
        <Card
          shadow="sm"
          style={{ width: 'calc(100% - 2rem)', maxWidth: '64rem', margin: '2rem 1rem 4rem', paddingBottom: '2rem' }}
        >
          <Versions />
        </Card>
      </Center>
    </TypographyStylesProvider>
  </React.StrictMode>
)

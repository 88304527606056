import { initializeApp } from 'firebase/app'
import { getStorage, listAll, ref, StorageReference } from 'firebase/storage'

const app = initializeApp({
  apiKey: 'AIzaSyAxQy7GGCJAMr7XsdfJg8DZI1hXzvkU_EQ',
  authDomain: 'napandup-cloud.firebaseapp.com',
  projectId: 'napandup-cloud',
  storageBucket: 'napandup-cloud.appspot.com',
  messagingSenderId: '791611227395',
  appId: '1:791611227395:web:6aca8b57cb00a615943d36'
})

const storage = getStorage(app, 'gs://napandup-mobile-apps')

export async function getVersions() {
  const versionRefs = await listAll(ref(storage, 'napandup'))

  return versionRefs.prefixes.reverse()
}

export async function getEnvironments(version: StorageReference) {
  const environmentRefs = await listAll(version)

  return environmentRefs.prefixes
}

async function getApps(platform: StorageReference){
  const appRefs = await listAll(platform)

  return appRefs.items.filter((file) => file.name.endsWith('.apk') || file.name.endsWith('.plist'))
}

export async function getPlatforms(environment: StorageReference){
  const platformRefs = await listAll(environment)

  return Promise.all(platformRefs.prefixes.map(async (platform) => ({
    app: (await getApps(platform)).pop(),
    platform: platform.name
  })))
}

export async function getApp(app: StorageReference) {
  const url = 'https://storage.googleapis.com/napandup-mobile-apps/' + app.fullPath

  return {
    name: app.name,
    url
  }
}
